import { capitalize, Divider, ListSubheader } from "@mui/material";
import { HBSelect } from "@components/hb-customs/hb-select";
import { useProductItem } from "@hooks/use-product-item";
import { AdminAPITypes } from "@stellar/api-logic";
import { useAppSelector } from "@store/store-helper";
import {
  allProductsOfTypeSelector,
  selectedProductIdentifierSelector,
} from "@store/plan-creation/plan-creation-selector";
import { useMemo } from "react";
import {
  isCreditProductIdentifier,
  isSphereXgProductIdentifier,
} from "@utils/type-guards";
import { CREDITS_DISPLAY_NAME } from "@utils/data-display";

interface Props {
  /** The subject type of the plan */
  planSubjectType: AdminAPITypes.ESubjectType;
}

/** A dropdown to select a product */
export function ProductDropdown({ planSubjectType }: Props): JSX.Element {
  const selectedProductIdentifier = useAppSelector(
    selectedProductIdentifierSelector
  );

  const features = useAppSelector(
    allProductsOfTypeSelector({
      productType: "feature",
      planSubjectType,
    })
  );
  const bundles = useAppSelector(
    allProductsOfTypeSelector({
      productType: "bundle",
      planSubjectType,
    })
  );
  const customProducts = useAppSelector(
    allProductsOfTypeSelector({
      productType: "custom",
      planSubjectType,
    })
  );

  const trialProducts = useAppSelector(
    allProductsOfTypeSelector({
      productType: "trial",
      planSubjectType,
    })
  );

  const sphereXGProducts = useMemo(
    () =>
      customProducts.filter((product) =>
        isSphereXgProductIdentifier(product.identifier)
      ),
    [customProducts]
  );

  const sphereXGCredits = useMemo(
    () =>
      customProducts.filter((product) =>
        isCreditProductIdentifier(product.identifier)
      ),
    [customProducts]
  );

  const { getMenuItem } = useProductItem();

  return (
    <HBSelect
      label={
        planSubjectType === AdminAPITypes.ESubjectType.device
          ? "License Type"
          : "Product"
      }
      value={selectedProductIdentifier?.identifier ?? ""}
      variant="outlined"
      isFullWidth
      isRequired
    >
      {sphereXGProducts.length > 0 && (
        <ListSubheader>Sphere XG Plans</ListSubheader>
      )}
      {sphereXGProducts.map(({ name, description, identifier }) =>
        getMenuItem({ type: "custom", name, description, identifier })
      )}

      {sphereXGProducts.length > 0 && <Divider />}

      {trialProducts.length > 0 && (
        <ListSubheader>Sphere XG Trial Plans</ListSubheader>
      )}
      {trialProducts.map(({ name, description, identifier }) =>
        getMenuItem({ type: "trial", name, description, identifier })
      )}

      {trialProducts.length > 0 && <Divider />}

      {sphereXGCredits.length > 0 && (
        <ListSubheader>
          Sphere XG {capitalize(CREDITS_DISPLAY_NAME)}
        </ListSubheader>
      )}
      {sphereXGCredits.map(({ name, description, identifier }) =>
        getMenuItem({ type: "custom", name, description, identifier })
      )}

      {sphereXGCredits.length > 0 && <Divider />}

      {bundles.length > 0 && (
        <ListSubheader>
          {planSubjectType === AdminAPITypes.ESubjectType.device
            ? "Available License types"
            : "Bundles"}
        </ListSubheader>
      )}
      {bundles.map(({ name, description, identifier }) =>
        getMenuItem({ type: "bundle", name, description, identifier })
      )}
      {bundles.length > 0 && <Divider />}

      {features.length > 0 && <ListSubheader>Features</ListSubheader>}
      {features.map(({ name, description, identifier }) =>
        getMenuItem({ type: "feature", name, description, identifier })
      )}
    </HBSelect>
  );
}
