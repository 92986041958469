import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import {
  BaseProduct,
  ProductIdentifier,
  ProductType,
} from "@store/plan-creation/plan-creation-slice-helper-types";
import { CREDITS_DISPLAY_NAME } from "@utils/data-display";
import {
  AllowedCreditProducts,
  AllowedCustomProductTypes,
  AllowedTrialProductTypes,
} from "@utils/product/product-types";
import { RequiredWithPartial } from "@utils/utility-types";
import { capitalize } from "lodash";

/** Maps the custom product ID to the product identifications that will be created for */
export const customProductIdToBundleIdentification: Record<
  AllowedCustomProductTypes,
  ProductIdentifier[]
> = {
  standard360: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  ],

  standardPointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
  ],

  pro360: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  ],

  proPointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
  ],

  enterprise360: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  ],

  enterprisePointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
  ],

  enterprise360PointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
  ],
  addCredits: [],
  addCreditsMinimum: [],
  addCreditsPopular: [],
  addCreditsProfessional: [],
  addCreditsEnterprise: [],
};

/** Maps the trial product ID to the product identifications that will be created for */
export const trialProductIdToBundleIdentification: Record<
  AllowedTrialProductTypes,
  ProductIdentifier[]
> = {
  enterprise360Trial: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXgTrialIndicator,
  ],

  enterprisePointCloudTrial: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXgTrialIndicator,
  ],

  enterprise360PointCloudTrial: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXgTrialIndicator,
  ],
};

/** Creates and returns credit product based on identifier and packageKey */
export function createCreditProduct(
  identifier: AllowedCreditProducts,
  packageKey: APITypes.CreditPackageValue
): RequiredWithPartial<BaseProduct<ProductType>, "value"> {
  return {
    identifier,
    name: `Add ${APITypes.CREDIT_PACKAGES[packageKey].value} ${capitalize(
      CREDITS_DISPLAY_NAME
    )} (${APITypes.CREDIT_PACKAGES[packageKey].name})`,
    description: `Add ${APITypes.CREDIT_PACKAGES[packageKey].value} ${CREDITS_DISPLAY_NAME} to the workspace`,
    scope: { [AdminAPITypes.ESubjectType.company]: true },
    value: APITypes.CREDIT_PACKAGES[packageKey].value,
  };
}
