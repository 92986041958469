import { AdminAPITypes } from "@stellar/api-logic";
import {
  ProductIdentifier,
  ProductMap,
  ProductType,
} from "@store/plan-creation/plan-creation-slice-helper-types";
import {
  AllowedAddons,
  AllowedAddonsTypes,
  AllowedBundles,
  AllowedBundlesTypes,
  AllowedCustomProducts,
  AllowedCustomProductTypes,
  AllowedSphereXgProducts,
  AllowedFeatures,
  AllowedFeatureTypes,
  AllowedSubscriptions,
  AllowedSubscriptionsTypes,
  AllowedTrialProducts,
  AllowedTrialProductTypes,
  AllowedCreditProducts,
} from "@utils/product/product-types";

// TODO: isCompanyContext and isUserContext functions should be removed in a new ticket.
// The User type is obsolete( Previously, Projects could exist without a workspace or company
// were associated with a single user account, but this structure is not used anymore).
// Currently, every context always includes a defined 'Company' object.

export function isCompanyContext(
  context:
    | AdminAPITypes.IAdmProjectCompanyContext
    | AdminAPITypes.IAdmProjectUserContext
): context is AdminAPITypes.IAdmProjectCompanyContext {
  return context.kind === "COMPANY";
}

/** Whether the product is a feature that is allowed in admin panel or not */
export function isProductAllowedFeature(
  feature: ProductIdentifier
): feature is AllowedFeatureTypes {
  return (AllowedFeatures as readonly string[]).includes(feature);
}

/** Whether the product is a bundle that is allowed in admin panel or not  */
export function isProductAllowedBundle(
  bundle: ProductIdentifier
): bundle is AllowedBundlesTypes {
  return (AllowedBundles as readonly string[]).includes(bundle);
}

/** Whether the product is a subscription that is allowed in admin panel or not  */
export function isProductAllowedSubscription(
  subscription: ProductIdentifier
): subscription is AllowedSubscriptionsTypes {
  return (AllowedSubscriptions as readonly string[]).includes(subscription);
}

/** Whether the product is an addon that is allowed in admin panel or not  */
export function isProductAllowedAddon(
  addon: ProductIdentifier
): addon is AllowedAddonsTypes {
  return (AllowedAddons as readonly string[]).includes(addon);
}

/** Whether the product is a custom product that is allowed in admin panel or not  */
export function isProductAllowedCustom(
  custom: ProductIdentifier
): custom is AllowedCustomProductTypes {
  return (Object.values(AllowedCustomProducts) as string[]).includes(custom);
}

/** Whether the product is a trial product that is allowed in admin panel or not  */
export function isProductAllowedTrial(
  custom: ProductIdentifier
): custom is AllowedTrialProductTypes {
  return (AllowedTrialProducts as readonly string[]).includes(custom);
}

/** Whether the product is a Sphere Xg custom product identifier that is allowed in admin panel or not  */
export function isSphereXgProductIdentifier<T extends ProductType>(
  productIdentifier?: ProductMap<T>
): productIdentifier is ProductMap<T> {
  if (!productIdentifier) {
    return false;
  }
  return (Object.values(AllowedSphereXgProducts) as string[]).includes(
    productIdentifier
  );
}

/** Whether the product is a credit product identifier that is allowed in admin panel or not  */
export function isCreditProductIdentifier<T extends ProductType>(
  productIdentifier?: ProductMap<T>
): productIdentifier is ProductMap<T> {
  if (!productIdentifier) {
    return false;
  }

  return (Object.values(AllowedCreditProducts) as string[]).includes(
    productIdentifier
  );
}
