import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimeUtils } from "@stellar/web-core";

interface IHBDatePickerProps {
  /** Label for the input */
  label: string;

  /** Date value of this input (rendered as defined by the `format` prop of the DatePicker component) */
  value: Date;

  /**
   * Function called when the input has been changed.
   * Date is `null` when the input is an invalid date (e.g. "June 20th, dsf")
   */
  handleOnChange(date: Date | null): void;

  /** Earliest date for the user to select */
  minDate?: Date;

  /** Message to show in case the user selected a date earlier than minDate */
  minDateMessage?: string;
}

/** A Customized Date Picker with minDate and message if the date is smaller than the minDate */
export function HBDatePicker({
  label,
  value,
  handleOnChange,
  minDate,
  minDateMessage,
}: IHBDatePickerProps): JSX.Element {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        sx={{ width: "100%" }}
        label={label}
        format="MMM dd, yyyy"
        value={value}
        onChange={(date) => {
          if (date && DateTimeUtils.isValidDate(date)) {
            handleOnChange(date);
          }
        }}
        minDate={minDate}
        slotProps={{
          textField: {
            helperText: minDateMessage,
          },
        }}
      />
    </LocalizationProvider>
  );
}
