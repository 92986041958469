import { AdminAPITypes, APITypes } from "@stellar/api-logic";

/** All the allowed feature products in Admin Panel */
export const AllowedFeatures = [
  APITypes.EUserSubscriptionRole.globalFaceBlurring,
  APITypes.EUserSubscriptionRole.ft_spotwalk,
  APITypes.EUserSubscriptionRole.ft_download_360,
  APITypes.EUserSubscriptionRole.ft_project_evaluation,
  APITypes.EUserSubscriptionRole.projectProgress,
  APITypes.EUserSubscriptionRole.insightsTeaser,
  APITypes.EUserSubscriptionRole.globalVideoMode,
  APITypes.EUserSubscriptionRole.globalProgressAi,
  APITypes.EUserSubscriptionRole.globalPointCloudSendTo,
  APITypes.EUserSubscriptionRole.sphereDashboard,
  APITypes.EUserSubscriptionRole.manageDataAccess,
] as const;
export type AllowedFeatureTypes = (typeof AllowedFeatures)[number];

/** All the allowed bundle products in Admin Panel */
export const AllowedBundles = [
  AdminAPITypes.EFeatureBundleIdentifier.dashboard,
  AdminAPITypes.EFeatureBundleIdentifier.education,
  AdminAPITypes.EFeatureBundleIdentifier.planFm,
  AdminAPITypes.EFeatureBundleIdentifier.premiumInsights,
  AdminAPITypes.EFeatureBundleIdentifier.gps,
  AdminAPITypes.EFeatureBundleIdentifier.snapshot,
  AdminAPITypes.EFeatureBundleIdentifier.vpm,
  AdminAPITypes.EFeatureBundleIdentifier.vpmEnterprise,
  AdminAPITypes.EFeatureBundleIdentifier.pointCloud,
  AdminAPITypes.EFeatureBundleIdentifier.cloudProcessing,
  AdminAPITypes.EFeatureBundleIdentifier.cad,
  AdminAPITypes.EFeatureBundleIdentifier.flash,
  AdminAPITypes.EFeatureBundleIdentifier.faroStream,
  AdminAPITypes.EFeatureBundleIdentifier.floorplanGenerator,
] as const;
export type AllowedBundlesTypes = (typeof AllowedBundles)[number];

/** All the allowed subscription products in Admin Panel */
export const AllowedSubscriptions = [
  AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard,
  AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro,
  AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
] as const;
export type AllowedSubscriptionsTypes = (typeof AllowedSubscriptions)[number];

/** All the allowed addon products in Admin Panel */
export const AllowedAddons = [
  AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
] as const;
export type AllowedAddonsTypes = (typeof AllowedAddons)[number];

/** All the allowed custom SphereXG products in Admin Panel */
export enum AllowedSphereXgProducts {
  standard360 = "standard360",
  standardPointCloud = "standardPointCloud",
  pro360 = "pro360",
  proPointCloud = "proPointCloud",
  enterprise360 = "enterprise360",
  enterprisePointCloud = "enterprisePointCloud",
  enterprise360PointCloud = "enterprise360PointCloud",
}

/** All the allowed custom credit products in Admin Panel */
export enum AllowedCreditProducts {
  addCredits = "addCredits",
  addCreditsSmall = "addCreditsSmall",
  addCreditsMedium = "addCreditsMedium",
  addCreditsLarge = "addCreditsLarge",
}

/** All the allowed custom in Admin Panel */
export const AllowedCustomProducts = {
  ...AllowedSphereXgProducts,
  ...AllowedCreditProducts,
};
export type AllowedCustomProductTypes =
  | AllowedSphereXgProducts
  | AllowedCreditProducts;

/** All the allowed trial products in Admin Panel */
export const AllowedTrialProducts = [
  "enterprise360Trial",
  "enterprisePointCloudTrial",
  "enterprise360PointCloudTrial",
] as const;
export type AllowedTrialProductTypes = (typeof AllowedTrialProducts)[number];
