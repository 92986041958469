import { runtimeConfig } from "@src/runtime-config";

/** Whether the app is running on production or not (staging or dev) */
export const isProductionEnvironment =
  runtimeConfig.environment === "production";

/** Whether the app is running on pre-production environments (staging or dev) or not */
export const isPreProductionEnvironment =
  runtimeConfig.environment === "dev" ||
  runtimeConfig.environment === "staging";
